var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MDamandMain"},[_c('img-item',{attrs:{"imgName":"rawpixel-1067110-unsplash"}}),_c('div',{staticClass:"tomato"}),_c('div',{staticClass:"MDamandCard"},[_c('div',{staticClass:"MDCardTran"}),_c('div',{staticClass:"MDCardTitle Medium"},[_vm._v("深入沟通，")]),_c('div',{staticClass:"MDCardTitle Medium"},[_vm._v("只为更深度的合作")]),_c('div',{staticClass:"MDCardText"},[_vm._v("收到消息后，我们将在第一时间与您取得联系！")]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'fullName',
            { rules: [{ required: true, message: '请填写您的姓名' }] }
          ]),expression:"[\n            'fullName',\n            { rules: [{ required: true, message: '请填写您的姓名' }] }\n          ]"}],staticStyle:{"height":"1.2rem"},attrs:{"placeholder":"姓名"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            { rules: [{ validator: _vm.checkPhone, trigger: 'blur' }] }
          ]),expression:"[\n            'phone',\n            { rules: [{ validator: checkPhone, trigger: 'blur' }] }\n          ]"}],staticStyle:{"height":"1.2rem"},attrs:{"placeholder":"联系电话"}})],1),_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'content',
            { rules: [{ required: true, message: '请填写您的需求或反馈' }] }
          ]),expression:"[\n            'content',\n            { rules: [{ required: true, message: '请填写您的需求或反馈' }] }\n          ]"}],attrs:{"placeholder":"填写您的需求或反馈","rows":3}})],1),_c('a-form-item',{staticStyle:{"text-align":"center"}},[_c('a-button',{staticClass:"subButton Medium",attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.subFeedBack}},[_vm._v(" 提交需求 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }