<template>
  <div
    style="
      width: 100%;
      position: relative;
      padding-bottom: 1.333333rem;
      background: #f5f6fb;
    "
  >
    <display-module
      :titleInfo="titleInfo"
      style="margin-bottom: 0.8rem; padding-top: 1.066667rem"
      v-show="showFlag"
    />
    <div
      class="MCooperationLine animate__animated animate__fadeInLeft animate__slow"
      v-show="showFlag"
    >
      <img-item imgName="阿里云 (1)" />
      <img-item imgName="蚂蚁金服" />
      <img-item imgName="高德打车" />
      <img-item imgName="首汽约车" />
    </div>
    <div
      class="MCooperationLine animate__animated animate__fadeInRight animate__slow"
      v-show="showFlag"
    >
      <img-item imgName="滴滴" />
      <img-item imgName="曹操出行" />
      <img-item imgName="阳光出行" />
      <img-item imgName="耀出行" />
    </div>
    <div
      class="MCooperationLine animate__animated animate__fadeInLeft animate__slow"
      v-show="showFlag"
    >
      <img-item imgName="12306" />
      <img-item imgName="电信" />
      <img-item imgName="KFc" />
      <img-item imgName="M" />
    </div>
    <div
      class="MCooperationLine animate__animated animate__fadeInRight animate__slow"
      v-show="showFlag"
    >
      <img-item imgName="中粮" />
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import DisplayModule from "@/components/MDisplayModule/index";
export default {
  components: {
    DisplayModule,
  },
  props: {
    showFlag: Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "cooperation",
        CHSTitle: "合作伙伴",
        instructions:
          "服务客户500+，覆盖众多主流行业，更多客户正在加入我们的合作",
      },
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.MCooperationLine {
  display: flex;
  justify-content: center;
  text-align: center;
}
.MCooperationLine >>> img {
  width: 2.16rem;
  height: 1.08rem;
}
.MCooperationLine div {
  width: 2.16rem;
  height: 1.08rem;
}
</style>
