<template>
  <div
    style="
      width: 100%;
      position: relative;
      padding-bottom: 0.666667rem;
      background: #f5f6fb;
    "
  >
    <display-module :titleInfo="titleInfo" style="margin-bottom: 0.8rem;padding-top:1.066667rem;" v-show="showFlag"/>
    <div class="MServiceLine">
      <div class="MServiceItem animate__animated animate__flipInY animate__slow" v-show="showFlag">
        <img-item imgName="移动APP定制开发" />
        <div class="MServiceItemTitle">移动APP定制开发</div>
        <div class="MServiceItemText">
          Android、IOS系统开发，满足全行业类型需求
        </div>
      </div>
      <div class="MServiceItem animate__animated animate__flipInY animate__slow" v-show="showFlag">
        <img-item imgName="微信定制开发" />
        <div class="MServiceItemTitle">微信定制开发</div>
        <div class="MServiceItemText">
          微信公众号、微信商城、H5开发、小程序开发
        </div>
      </div>
    </div>
    <div class="MServiceLine">
      <div class="MServiceItem animate__animated animate__flipInY animate__slow" v-show="showFlag">
        <img-item imgName="企业开发" />
        <div class="MServiceItemTitle">企业开发</div>
        <div class="MServiceItemText">
          企业集团OA系统、开发CRM、ERP系统
        </div>
      </div>
      <div class="MServiceItem animate__animated animate__flipInY animate__slow" v-show="showFlag">
        <img-item imgName="网站开发" />
        <div class="MServiceItemTitle">网站开发</div>
        <div class="MServiceItemText">
          公司官网定制开发，电商系统、社交平台等开发
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DisplayModule from "@/components/MDisplayModule/index";
export default {
  components: {
    DisplayModule,
  },
  props:{
    showFlag: Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "service",
        CHSTitle: "核心服务",
        instructions:
          "丰富的产品设计经验+资深的开发能力，让您享受互联网大企业般的服务。",
      },
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.MServiceLine {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: .666667rem;
}
.MServiceItem {
  text-align: center;
}
.MServiceItem >>> img {
  width: 3.28rem;
  height: 3.28rem;
}
.MServiceItem div {
  width: 3.28rem;
}
.MServiceItemTitle {
  font-size: 0.4rem;
  color: #3a416b;
  line-height: 0.56rem;
  letter-spacing: 0.013333rem;
}
.MServiceItemText {
  font-size: 0.266667rem;
  color: #6d738e;
  line-height: 0.36rem;
  letter-spacing: 0.013333rem;
  margin-top: .24rem;
}
</style>
