<template>
  <div class="MDamandMain">
    <img-item imgName="rawpixel-1067110-unsplash" />
    <div class="tomato"></div>
    <div class="MDamandCard">
      <div class="MDCardTran"></div>
      <div class="MDCardTitle Medium">深入沟通，</div>
      <div class="MDCardTitle Medium">只为更深度的合作</div>
      <div class="MDCardText">收到消息后，我们将在第一时间与您取得联系！</div>
      <a-form :form="form">
        <a-form-item>
          <a-input
            style="height: 1.2rem"
            v-decorator="[
              'fullName',
              { rules: [{ required: true, message: '请填写您的姓名' }] }
            ]"
            placeholder="姓名"
          />
        </a-form-item>
        <a-form-item>
          <a-input
            style="height: 1.2rem"
            v-decorator="[
              'phone',
              { rules: [{ validator: checkPhone, trigger: 'blur' }] }
            ]"
            placeholder="联系电话"
          />
        </a-form-item>
        <a-form-item>
          <a-textarea
            placeholder="填写您的需求或反馈"
            v-decorator="[
              'content',
              { rules: [{ required: true, message: '请填写您的需求或反馈' }] }
            ]"
            :rows="3"
          />
        </a-form-item>
        <a-form-item style="text-align: center">
          <a-button
            type="primary"
            html-type="submit"
            class="subButton Medium"
            @click="subFeedBack"
          >
            提交需求
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { postAction } from "@/api/manage.js";
import crypto from "crypto";
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      url: {
        list: "/http/helloword"
      }
    };
  },
  methods: {
    checkPhone(rule, value, callback) {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    },
    subFeedBack() {
      var dingdingUrl =
        "/dingding/robot/send?access_token=19844ee7077dd3de1c37319d5973ce3f6ac4a320388c080ee97829d2b1a1b4e8";
      var secret =
        "SECbec77b7cac372d99ffa982ea27f72c68cc7b03badb12e388c782b2f975ab2acb";

      var timestamp = new Date().getTime();
      var stringToSign = timestamp + "\n" + secret;
      var base = crypto
        .createHmac("sha256", secret)
        .update(stringToSign)
        .digest("base64");
      var sing = encodeURIComponent(base);
      var dingdingStr = dingdingUrl + `&timestamp=${timestamp}&sign=${sing}`;
      console.log("dingdingStr", dingdingStr);

      var that = this;
      var time = new Date();
      this.form.validateFields((err, values) => {
        if (!err) {
          var data =
            "##### 新消息  \n  - **联系人**  \n  " + 
            values.fullName + 
            "  \n  - **联系方式**  \n  " + 
            values.phone +
            "  \n  - **内容**  \n  " + 
            values.content +
            "  \n  ###### " +
            time.toLocaleDateString() +
            " " +
            time.toLocaleTimeString();
            
          var params = {
            msgtype : 'markdown',
            markdown: {
              title: '来自官网的消息',
              text : data
            },

          }
          this.$confirm({
            title: "确认提交用户反馈?",
            content: "请仔细填写反馈，方便我们第一时间与您取得联系。",
            onOk() {
              postAction(dingdingStr, params).then(res => {
                that.form.resetFields;
              });
            },
            onCancel() {}
          });
        }
      });
    }
  },
  created() {}
};
</script>
<style scoped>
.MDamandMain {
  width: 100%;
  height: 15.066667rem;
  padding: 1.066667rem 0.666667rem 0.8rem;
  overflow: hidden;
  position: relative;
}

.MDamandMain >>> img{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
}
.MDamandCard {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  padding: 0.64rem 0.4rem 0.773333rem;
}
.MDCardTran {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.786667rem;
  background: linear-gradient(
    to bottom right,
    #f3f5ff 0%,
    #f3f5ff 50%,
    transparent 50%,
    transparent 100%
  );
}
.tomato {
  position: absolute;
  top: 0;
  left: 0;
  width: 112%;
  height: 6.226667rem;
  background: linear-gradient(
    to bottom right,
    #2b7bed 0,
    rgba(43, 123, 237, 0) 50%,
    transparent 50%,
    transparent 100%
  );
}
.MDCardTitle {
  position: relative;
  font-size: 0.64rem;
  color: #000000;
  line-height: 0.866667rem;
  letter-spacing: 0.053333rem;
}
.subButton {
  width: 6rem;
  height: 1.2rem;
  background: #2b7bed;
  font-size: 0.453333rem;
  color: #ffffff;
  line-height: 0.626667rem;
  letter-spacing: 0.026667rem;
  border-radius: 0;
}
.MDCardText {
  position: relative;
  font-size: 0.4rem;
  color: #333333;
  line-height: 0.56rem;
  letter-spacing: 0.026667rem;
  margin: 0.173333rem 0 1.066667rem;
}
</style>
