<template>
  <div class="MDataBg">
    <img-item imgName="编组 2 (3)" />
    <div class="MDataTitle Medium">10年时间 80位同事 3000个日夜</div>
    <div class="MDataText">
      多创拥有一支经验丰富、眼光独到、自信尽职、专业创新的高端网站建设团队，凭着多年的实践经验，一直坚持高标准的服务。多创在为您做的，不仅仅是一次服务，而是与您一起，缔造一个属于您的网络品牌
    </div>
    <div class="MDataLine" style="margin:1.333333rem 0 .8rem;">
      <div>
        <count-to
          :startVal="0"
          :endVal="500"
          :duration="3000"
          class="MDataNum"
          v-if="showFlag"
        ></count-to>
        <div>500+多家公司达成合作</div>
      </div>
      <div>
        <count-to
          :startVal="0"
          :endVal="1000"
          :duration="3000"
          class="MDataNum"
          v-if="showFlag"
        ></count-to>
        <div>1000+多万用户在使用中</div>
      </div>
    </div>
    <div class="MDataLine" style="padding-bottom:1.3rem;">
      <div>
        <count-to
          :startVal="0"
          :endVal="2000"
          :duration="3000"
          class="MDataNum"
          v-if="showFlag"
        ></count-to>
        <div>2000+多项目部署上线</div>
      </div>
      <div>
        <count-to
          :startVal="0"
          :endVal="5000"
          :duration="3000"
          class="MDataNum"
          v-if="showFlag"
        ></count-to>
        <div>5000+多次会议沟通交流</div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  components: {
    countTo,
  },
  props:{
    showFlag: Boolean
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.MDataBg {
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
}
.MDataBg >>> img{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
}
.MDataTitle {
  position: relative;
  font-size: 0.586667rem;
  line-height: 0.8rem;
  letter-spacing: 0.013333rem;
  padding: 1.04rem 0 0.253333rem;
}
.MDataText {
  position: relative;
  width: 6.973333rem;
  margin: 0 auto;
  font-size: 0.266667rem;
  line-height: 0.4rem;
  letter-spacing: 0.026667rem;
  text-align: left;
}
.MDataLine {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.MDataNum {
  font-size: 0.96rem;
  font-family: SFUDINEngschrift;
  color: #2b7bed;
  line-height: 1.306667rem;
  letter-spacing: 0.066667rem;
  -webkit-text-stroke: 0.013333rem #2b7bed;
}
</style>
