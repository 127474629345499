<template>
  <div style="width: 100%; position: relative;padding-bottom:1.333333rem;">
    <div class="advantageBox1"></div>
    <div class="advantageBox2"></div>
    <display-module
      :titleInfo="titleInfo"
      style="margin-bottom: 0.8rem;padding-top:1.066667rem;"
    />
    <div ref="item1">
      <div
        class="advantageItem animate__animated animate__fadeInUp animate__slow"
        v-show="item1Flag"
      >
        <div class="advantageItemLeft">
          <img-item imgName="专业团队 (1)" />
          <div class="Medium">专业团队</div>
        </div>
        <div class="advantageItemRight">
          多创拥有众多大厂经验的软件开发工程师、项目经理。拥有丰富项目经验，开发过12306订票软件、建发国际ERP系统、智慧法院、B2B电商平台等项目。
        </div>
      </div>
    </div>

    <div ref="item2">
      <div
        class="advantageItem animate__animated animate__fadeInUp animate__slow"
        v-show="item2Flag"
      >
        <div class="advantageItemLeft">
          <img-item imgName="先进技术" />
          <div class="Medium">先进技术</div>
        </div>
        <div class="advantageItemRight">
          从立项初期即时刻关注客户满意度，及时反馈和解决问题。与客户建立长久良好的合作关系共同应对工作中面临的各项挑战。
        </div>
      </div>
    </div>
    <div ref="item3">
      <div
        class="advantageItem animate__animated animate__fadeInUp animate__slow"
        v-show="item3Flag"
      >
        <div class="advantageItemLeft">
          <img-item imgName="严谨保护" />
          <div class="Medium">严密保护</div>
        </div>
        <div class="advantageItemRight">
          和每个用户签订专门的《保密协议》，无论在服务期内还是服务期外，我们将严格遵守保密义务，保证客户的知识产权和竞争优势。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DisplayModule from "@/components/MDisplayModule/index";
export default {
  components: {
    DisplayModule
  },
  props: {
    showFlag: Boolean
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "advantage",
        CHSTitle: "我们的优势",
        instructions:
          "我们时刻保持高度的责任感，敬业的精神，专业的素质。以“新技术、高效率、专业化”为核心。致力于为用户提供极致的使用体验，无忧的全包服务。与每一位客户建立长久良好的合作。"
      },
      item1Flag: false,
      item2Flag: false,
      item3Flag: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 实现当滚动到指定位置，触发动画
      let _this = this;
      let refArray = [
        { ref: "item1", show: "item1Flag" },
        { ref: "item2", show: "item2Flag" },
        { ref: "item3", show: "item3Flag" }
      ];
      refArray.forEach((r, i) => {
        _this[r.show] = _this.gdjz(r.ref, 20);
      });
    },
    gdjz(div, offset) {
      let dom = this.$refs[div];
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        return b + c > a;
      }
    }
  },
  created() {}
};
</script>
<style scoped>
.advantageBox1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 5rem;
  height: 12.253333rem;
  z-index: 1;
  background: linear-gradient(180deg, #2b7bed 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.2;
}
.advantageBox2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.506667rem;
  height: 12.253333rem;
  z-index: 5;
  background: linear-gradient(180deg, #2b7bed 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.1;
}
.advantageItem {
  position: relative;
  z-index: 10;
  width: 8.666667rem;
  margin: 0 auto 0.4rem;
  padding: 0.533333rem 0.4rem;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0.2rem 0.2rem 0.8rem 0rem rgba(50, 59, 70, 0.1);
  border-radius: 0.133333rem;
}
.advantageItemLeft {
  text-align: center;
}
.advantageItemLeft >>> img {
  width: 1.28rem;
  height: 1.28rem;
  margin: 0.08rem 0.44rem 0.08rem 0.44rem;
}
.advantageItemLeft div {
  color: #375375;
  line-height: 0.506667rem;
  letter-spacing: 0.013333rem;
  font-size: 0.373333rem;
}
.advantageItemRight {
  font-size: 0.266667rem;
  color: #6d738e;
  line-height: 0.466667rem;
  letter-spacing: 0.026667rem;
  margin-left: 0.36rem;
  padding-top: 0.186667rem;
}
</style>
