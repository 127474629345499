<template>
  <div style="width: 100%">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item class="swipeItem">
        <img-item imgName="编组 2 (2)" />
        <div class="swipeView">
          <div class="swipeViewTitle Medium">D-TRON</div>
          <div class="swipeViewTitle Medium">科技引领未来·共创卓越品质</div>
          <div class="swipeLineBox"></div>
          <div class="swipeViewText">致力于为全球中小型企业提供专业，智能，客制化的产品和解决方案，携手企业共赢新时代！</div>
        </div>
      </van-swipe-item>
      <van-swipe-item class="swipeItem">
        <img-item imgName="austin-distel-rxpThOwuVgE-unsplash (2)" />
        
        <div class="swipeView">
          <div class="swipeViewTitle Medium">产品驱动·技术驱动·品牌驱动</div>
          <div class="swipeLineBox"></div>
          <div class="swipeViewText">比起简单粗暴的定制开发，我们更希望与您基于经营场景、行业属性、用户行为。一同打造具备体验温度的好产品。</div>
        </div>
      </van-swipe-item>
      <van-swipe-item class="swipeItem">
        <img-item imgName="austin-distel-rxpThOwuVgE-unsplash (3)" />
        <div class="swipeView">
          <div class="swipeViewTitle Medium">契合互联网产品思维</div>
          <div class="swipeLineBox"></div>
          <div class="swipeViewText">基于客户切实业务需求，打造用户极致产品体验</div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.swipeItem{
  width: 10rem;
  height: 13.2rem;
  position: relative;
}
.swipeItem >>> img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.swipeView {
  position: absolute;
  z-index: 10;
  color: #fff;
  margin: 4.213333rem 0.4rem 0;
}
.swipeViewTitle {
  font-size: 0.666667rem;
  line-height: 0.933333rem;
  letter-spacing: 0.026667rem;
}
.swipeLineBox {
  width: 1.2rem;
  height: 0.106667rem;
  background: #2b7bee;
  margin: 0.333333rem 0;
}
.swipeViewText {
  font-size: .4rem;
  line-height: .56rem;
  letter-spacing: .026667rem;
  opacity: 0.8;
}
</style>
