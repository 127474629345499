<template>
  <div style="width: 100%; position: relative; padding-bottom: 1.52rem">
    <display-module :titleInfo="titleInfo" style="margin-bottom: 0.8rem;padding-top:1.066667rem;" />
    <div class="process">产品研发流程{{ selectIndex + 1 }}/8</div>
    <div class="MProcedureView">
      <div
        class="processItem"
        v-for="(item, index) in processList"
        :key="index"
        @click="swipeToItem(index)"
        v-if="index < 4"
      >
        <div class="processView">
          <img-item :imgName="selectIndex == index ? item.selectedUrl : item.selectUrl" />
          <div :class="selectIndex == index ? 'textSelect' : 'textNoSelect'">
            0{{ index + 1 }}
          </div>
        </div>
        <a-icon type="double-right" class="processIcon" v-if="index < 3" />
      </div>
    </div>
    <div style="text-align: right; padding: 0 1.426667rem">
      <a-icon
        type="double-right"
        class="processIcon"
        style="transform: rotate(90deg)"
      />
    </div>
    <div class="MProcedureView" style="flex-direction: row-reverse">
      <div
        class="processItem"
        v-for="(item, index) in processList"
        :key="index"
        @click="swipeToItem(index)"
        v-if="3 < index && index < 8"
      >
        <div class="processView">
          <img-item :imgName="selectIndex == index ? item.selectedUrl : item.selectUrl" />
          <div :class="selectIndex == index ? 'textSelect' : 'textNoSelect'">
            0{{ index + 1 }}
          </div>
        </div>
        <a-icon
          type="double-left"
          class="processIcon"
          v-if="4 < index && index < 8"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0.706667rem 1.166667rem 0;
      "
    >
      <div class="arrowLeft" @click="prevItem()">
        <img-item imgName="更多 (3)备份" />
      </div>
      <van-swipe class="my-swipe" :show-indicators="false" ref="swipe" @change="changeIndex">
        <van-swipe-item v-for="(item, index) in processList" :key="index" >
          <!-- <img :src="item.selectedUrl" /> -->
          <div class="MSwipeItemImg">
            <img-item :imgName="item.name" />
          </div>
          <div class="MSwipeItemTitle">0{{ index + 1 }}.{{ item.name }}</div>
          <div class="MSwipeItemText">{{ item.introduce }}</div>
        </van-swipe-item>
      </van-swipe>
      <div class="arrowRight" @click="nextItem()">
        <img-item imgName="更多 (3)备份" />
      </div>
    </div>
  </div>
</template>

<script>
import DisplayModule from "@/components/MDisplayModule/index";
export default {
  components: {
    DisplayModule,
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "procedure",
        CHSTitle: "服务流程",
        instructions:
          "完善的产品研发流程，层层把关，全身心投入，只为客户满意。",
      },
      selectIndex: 0,
      processList: [
        {
          name: "项目洽谈",
          introduce: "销售人员与客户进行项目谈判，确定初步意向，提交意向金",
          selectUrl:"项目洽谈",
          selectedUrl:"项目洽谈备份 2"
        },
        {
          name: "需求评估",
          introduce: "提供解决方案，制作报价文档，30分钟获取报价方案",
          selectUrl:"需求评估",
          selectedUrl:"需求评估 (1)"
        },
        {
          name: "需求调研",
          introduce: "需求分析师与客户沟通需求吗，整理需求清单",
          selectUrl:"需求调研",
          selectedUrl:"需求调研 (1)"
        },
        {
          name: "产品规划",
          introduce: "需求规划，资料整理，原型图制作",
          selectUrl:"产品规划",
          selectedUrl:"产品规划 (1)"
        },
        {
          name: "UI设计",
          introduce: "设计分析，页面设计，体验优化",
          selectUrl:"UI设计",
          selectedUrl:"UI设计 (1)"
        },
        {
          name: "程序开发",
          introduce: "搭建系统架构，业务逻辑、功能开发",
          selectUrl:"程序开发",
          selectedUrl:"程序开发 (1)"
        },
        {
          name: "测试交付",
          introduce: "原型图、设计稿、源码、操作文档交付",
          selectUrl:"测试交付",
          selectedUrl:"测试交付 (1)"
        },
        {
          name: "部署上线",
          introduce: "域名解析，服务器环境部署",
          selectUrl:"部署上线",
          selectedUrl:"部署上线 (1)"
        },
      ],
    };
  },
  methods: {
    prevItem(){
      this.$refs.swipe.prev()
    },
    nextItem(){
      this.$refs.swipe.next()
    },
    swipeToItem(index){
      this.$refs.swipe.swipeTo(index)
    },
    changeIndex(index){
      this.selectIndex = index
    }
  },
  created() {},
};
</script>
<style scoped>
.process {
  font-size: 0.266667rem;
  color: rgba(51, 51, 51, 0.5);
  line-height: 0.4rem;
  letter-spacing: 0.026667rem;
  text-align: center;
  margin-bottom: 0.4rem;
}
.MProcedureView {
  display: flex;
  justify-content: center;
}
.processItem {
  display: flex;
  text-align: center;
}
.processIcon {
  font-size: 0.266667rem;
  color: #000;
  margin: 0.346667rem 0.4rem;
}
.processView >>> img {
  width: 0.96rem;
  height: 0.96rem;
}
.processView div {
  font-size: 0.266667rem;
  line-height: 0.36rem;
  margin-top: 0.053333rem;
  letter-spacing: 0.013333rem;
}
.textNoSelect {
  color: rgba(51, 51, 51, 0.49);
}
.textSelect {
  color: #4f4f4f;
}
.my-swipe .van-swipe-item {
  /* padding-right: 0.506667rem; */
  text-align: center;
}
.my-swipe .van-swipe-item div {
  width: 4.08rem;
}
.arrowLeft >>> img {
  position: absolute;
  top: 50%;
  margin-top: -0.26rem;
  left: 1.18rem;
  width: 0.333333rem;
  height: 0.533333rem;
}
.arrowRight >>> img  {
  position: absolute;
  top: 50%;
  margin-top: -0.26rem;
  right: 1.48rem;
  transform: rotate(180deg);
  width: 0.333333rem;
  height: 0.533333rem;
}
.MSwipeItemTitle {
  margin: 0.24rem auto 0.293333rem;
  font-size: 0.48rem;
  color: #3a416b;
  line-height: 0.666667rem;
  letter-spacing: 0.026667rem;
}

.MSwipeItemImg{
  margin: 0 auto;
}
.MSwipeItemImg >>> img{
  
  width: 2.08rem;
  height: 2.08rem;
}
.MSwipeItemText {
  font-size: 0.32rem;
  margin: 0 auto;
  color: #6d738e;
  line-height: 0.44rem;
  letter-spacing: 0.013333rem;
}
</style>
